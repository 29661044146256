import React, { useEffect, useState } from "react";
import { CLASSIC_API_URL, VENUE_GUID, hoverData } from "../../constants";
import moment from "moment-timezone";

import dataService from "../../apiServices/data.service";
import MainLayout from "../../components/Layout/MainLayout";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import PhoneNumberFormat from "../../components/CommonComponents/PhoneNumberFormat";
import { handleTrimCountryCode, sortByKey } from "../../commonFunctions";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import StaticDataTable from "../../components/CommonComponents/DataTable/StaticDataTable";
import ReactDOM from "react-dom";

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const [show, setShow] = useState(false);

  const [clampedIndexes, setClampedIndexes] = useState([]);

  useEffect(() => {
    const checkClamped = () => {
      const indexes = [];
      reservations.forEach((item, index) => {
        const element = document.getElementById(`p-${index}`);
        if (element) {
          const isClamped = element.scrollHeight > element.clientHeight;
          if (isClamped) indexes.push(index);
        }
      });
      setClampedIndexes(indexes);
    };

    checkClamped();
  }, [reservations]);

  // useEffect(() => {
  //   const checkCharacterCount = () => {
  //     const indexes = [];
  //     reservations &&
  //       reservations.forEach((item, index) => {
  //         if (item.notes.length > 10) {
  //           indexes.push(index);
  //         }
  //       });
  //     setClampedIndexes(indexes);
  //   };

  //   checkCharacterCount();
  // }, [reservations]);

  /* useEffect(() => {
    setShow(true);
    dataService
      .getTodaysReservations({ VenueGuid: VENUE_GUID })
      .then((response) => {
        if (response.data.status === "success") {
          setReservations(
            response.data.response.length > 0
              ? sortByKey(response.data.response, "real_datetime_of_slot")
              : []
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setShow(false);
      });
  }, []); */

  /* "id": "ahNzfnNldmVucm9vbXMtc2VjdXJlciULEhtuaWdodGxvb3BfUmVzZXJ2YXRpb25BY3R1YWwYseDL9SsM",
  "first_name": "Eva",
  "last_name": "Balansay",
  "reference_code": "39H5R547",
  "client_reference_code": "5F04W00",
  "is_vip": false,
  "date": "2024-05-14",
  "real_datetime_of_slot": "2024-05-14 12:00:00",
  "time_slot_iso": "12:00:00",
  "status": "NOT_RECONCILED",
  "max_guests": 4,
  "notes": "Selected: One Golf Bay ",
  "arrival_time": "12:00 PM",
  "duration": 60,
  "reservation_type": "",
  "tags": [],
  "phone_number": "+17025958506",
  "email": "gandaganda98@yahoo.com" */
  const columns = [
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone_number",
      name: "phone_number",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "150",
    },
    {
      data: "email",
      name: "email",
      title: "Email",
      width: "100",
      searchable: true,
      placeholder: "Filter by email",
    },
    {
      data: "max_guests",
      name: "max_guests",
      title: "Group Size",
      width: "150",
    },
    {
      data: "date",
      name: "date",
      title: "Fast Pass for Date",
      width: "200",
      render: function (data, type, row, meta) {
        return moment(data).format("DD MMM YYYY");
      },
    },
    {
      data: "arrival_time",
      name: "arrival_time",
      title: "Fast Pass Time",
      width: "150"
    },
    {
      data: "notes",
      name: "notes",
      title: "Notes",
      width: "150",
    },
    {
      data: "status",
      name: "status",
      title: "Status",
      width: "100",
    },


  ];


  const columnDefs = [
    {
      targets: 1,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone_number} />
          </>,
          td
        );
      },
    },
    {
      targets: 6,
      className: "dt-head-center",
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.notes ? (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-$`}>
                    <p className="mb-0"> {rowData.notes}</p>
                  </Tooltip>
                }
              >
                <div className="d-flex">
                  <div className="text-wraps ">
                    {" "}
                    {rowData.notes}
                  </div>
                  <span>
                    <i className="fa-regular  fa-note-sticky"></i>
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="d-flex justify-content-center">-</div>
            )}
          </>,
          td
        );
      },
    },
    {
      targets: 7,
      className: "dt-head-center",
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.status == "CONFIRMED" ? (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-$`}>
                    <p>{hoverData[rowData.status]}</p>
                  </Tooltip>
                }
              >
                <span> {rowData.status}</span>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-$`}>
                    <p>{hoverData[rowData.status]}</p>
                  </Tooltip>
                }
              >
                <span className="text-theme">
                  {rowData.status}
                </span>
              </OverlayTrigger>
            )}
          </>,
          td
        );
      },
    }
  ];

  const url = {
    url: CLASSIC_API_URL + "sevenroom-module/get-todays-reservations",
    type: "POST",
    data: {
      VenueGuid: VENUE_GUID
    }
  };
  return (
    <MainLayout>
      {show ? (
        <ThreeDotsLoader />
      ) : (
        <div className="table-responsive">
          <StaticDataTable
            id="fastpass-reservations"
            url={url}
            columns={columns}
            columnDefs={columnDefs}
          />
          {/* <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th width="150">Phone</th>
                <th width="250">Email</th>
                <th>Group Size</th>
                <th width="150">Fast Pass for Date</th>
                <th>Fast Pass Time</th>
                <th>Notes</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {reservations.length > 0
                ? reservations.map((reservation, i) => {
                    return (
                      <tr id={reservation.id}>
                        <td>
                          {reservation.first_name} {reservation.last_name}
                        </td>
                        <td>
                          <PhoneNumberFormat
                            phone={handleTrimCountryCode(
                              reservation.phone_number
                            )}
                          />
                        </td>
                        <td>{reservation.email}</td>
                        <td>{reservation.max_guests}</td>
                        <td>
                          {moment(reservation.date).format("DD MMM YYYY")}
                        </td>
                        <td>{reservation.arrival_time}</td>
                        <td>
                          {reservation.notes ? (
                            <OverlayTrigger
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-$`}>
                                  <p> {reservation.notes}</p>
                                </Tooltip>
                              }
                            >
                              <div className="d-flex">
                                <div className="text-wraps">
                                  {reservation.notes}
                                </div>
                                <span>
                                  <i className="fa-regular  fa-note-sticky"></i>
                                </span>
                              </div>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {reservation.status == "CONFIRMED" ? (
                            <OverlayTrigger
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-$`}>
                                  <p>{hoverData[reservation.status]}</p>
                                </Tooltip>
                              }
                            >
                              <span> {reservation.status}</span>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-$`}>
                                  <p>{hoverData[reservation.status]}</p>
                                </Tooltip>
                              }
                            >
                              <span className="text-theme">
                                {reservation.status}
                              </span>
                            </OverlayTrigger>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : "No reservations today"}
            </tbody>
          </Table> */}
        </div>
      )}
    </MainLayout>
  );
};

export default Reservations;
