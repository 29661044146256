import React from 'react'
import PhoneInput from 'react-phone-input-2';

const PhoneNumberFormat = ({phone}) => {
  return (
    <>
    <PhoneInput
                                readOnly
                                disableDropdown={true}
                                specialLabel={false}
                                placeholder="000 000-0000"
                                value={(phone)}
                                country='us'
                                disabled={true}
                                disableCountryCode={true}
                                onlyCountries={['us']}
                            />
    </>
  )
}

export default PhoneNumberFormat