import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  hideCanves,
  showCanves,
  showProfile,
  hideProfile,
} from "../../features/offCanvesSlice";

import { VENUE_GUID } from "../../constants";
import { showForm, hideForm } from "../../features/showAccountFormSlice";
import { reloadTable } from "../../features/reloadDataTableSlice";
import "react-phone-number-input/style.css";
import SelectProfile from "./SelectProfile";
import ProfileOffcanvas from "./ProfileOffcanvas";
import dataService from "../../apiServices/data.service";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import tokenService from "../../apiServices/token.service";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from "react-phone-number-input";
import RequiredComponents from "../../components/CommonComponents/RequiredComponents/RequiredComponents";
import { getProfile, updateProfileUrl } from "../../features/profileSlice";


const EditProfile = ({ id, callBack, profileUrl }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile)

  const [user] = useState(tokenService.getUser())
  const [type, setType] = useState();

  useEffect(() => {
    if (profileUrl) {
      dispatch(updateProfileUrl(profileUrl))

    }
  }, [profileUrl])


  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber('+1' + value);

    return isValid
  }

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().trim()
      .required('Please enter first name.'),
    LastName: Yup.string().trim()
      .required('Please enter last name.'),
    Phone: Yup.string()
      .required()
      .when(['phone'], (value, schema) => {

        if (Number(value)) {
          return schema
            .required()
        }
        return schema;
      }).test('phone', 'Phone number is invalid.', (value, ref) => {
        return handleValidate(value);
      }),
  }, [['phone', 'phone']]);

  const formik = useFormik({
    initialValues: profile,
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {

      const filteredData = {
        PersonsGuid: profile.PersonsGuid,
        VenueGuid: VENUE_GUID,
        FirstName: values.FirstName,
        LastName: values.LastName,
        Phone: values.Phone,
        AvatarFileUrl:values.AvatarFileUrl
      }


      dataService.updateEmployee(filteredData).then((res) => {
        if (res.data.status == "success") {
          dispatch(getProfile(profile.Email))
          toast.success("Profile updated.")
          callBack("view")
        } else {
          toast.error(res.data.errorMessage)
        }
      })

    },
  });

  const onValueChange = (phoneNumber) => {
    formik.setFieldValue('Phone', phoneNumber);
  };

  return (
    <>
      <div className="offcanvas-content">
        <Form onSubmit={formik.handleSubmit}>
          <Row className="membership-offcanvs-width">
            <div className="col-md-4 col-12  text-center  position-relative">
              <div className="image-upload  ">
                {/* <img
                  src={` ${profileUrl ? profileUrl : profile.avatar_file_url ? profile.avatar_file_url : 'img/profile-pic.png' }`}
                  className="popup-img"
                  alt="..."
                /> */}
                <img
                  src={` ${profile.AvatarFileUrl ? profile.AvatarFileUrl : 'img/profile-pic.png'}`}
                  className="popup-img"
                  alt="..."
                />
                {/* <label for="files" className="link text-primary mt-2 ">Change Avatar</label> */}
                {/* <input id="files" type="file" className="d-none" /> */}
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={() => {
                    callBack("select");
                  }}
                >
                  Change Avatar
                </Button>
              </div>
            </div>

            <div className="col-md-8  mt-md-0 file-membership-edit">
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control type="hidden" name='avatar_file_url' onChange={formik.handleChange} value={profileUrl} />
                <Form.Label> First Name <RequiredComponents /></Form.Label>
                <Form.Control type="text" name='FirstName' placeholder="First Name" onChange={formik.handleChange} value={formik.values.FirstName} />
                {formik.touched.FirstName && formik.errors.FirstName ? (
                  <div className="custom-invalid-feedback">
                    {formik.errors.FirstName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="formNamel" className="mb-3">
                <Form.Label> Last Name <RequiredComponents /></Form.Label>
                <Form.Control type="text" name='LastName' placeholder="Last Name" onChange={formik.handleChange} value={formik.values.LastName} />
                {formik.touched.LastName && formik.errors.LastName ? (
                  <div className="custom-invalid-feedback">
                    {formik.errors.LastName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Phone <RequiredComponents /></Form.Label>
                <PhoneInput
                  inputProps={{
                    name: 'Phone', // should match with the schema and initialValues
                  }}
                  disableDropdown={true}
                  onChange={onValueChange} // 👈
                  specialLabel={false}
                  value={formik.values.Phone}
                  placeholder="000 000-0000"
                  country='us'
                  disableCountryCode={true}
                  onlyCountries={['us']}
                  id="phone-input"
                />
                {formik.touched.Phone && formik.errors.Phone ? (
                  <div className="custom-invalid-feedback">
                    {formik.errors.Phone}
                  </div>
                ) : null}
              </Form.Group>


            </div>
          </Row>
          <div className="modal-footer gap-2">
            <Button
              type="button"
              variant="secondary"
              className="btn btn-secondary  fw_medium   ms-2 rounded-1"
              data-bs-dismiss="modal"
              onClick={() => callBack("view")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary fw_medium   ms-2 rounded-1"
            >
              Save
            </Button>
          </div>
        </Form>
        {type === "select" ? <>{callBack("select")}</> : ""}
      </div>
      <div className='unsave-changes'></div>

    </>
  );
};

export default EditProfile;
