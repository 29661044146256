import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Accordion, Col, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import RequiredComponents from "../../components/CommonComponents/RequiredComponents/RequiredComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  IntakeBayFilters,
  resetIntakeBayFilter,
} from "../../features/baysSlice";
import dataService from "../../apiServices/data.service";
import { VENUE_GUID } from "../../constants";
import {
  reloadTable,
  resetReloadTable,
} from "../../features/reloadDataTableSlice";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";

const EditIntake = ({ handleClose3, waitListGuid }) => {
  const [btnIsDisable, setBtnIsDisable] = useState(false);
  const [showLoader, setSetLoader] = useState(false);
  const [value, setValue] = useState();
  const { intake_bays, intake_bay_filter, floors, bay_types, loading, error } =
    useSelector((state) => state.bays);

  const initialObject = {
    Name: "",
    VenueGuid: VENUE_GUID,
    Phone: "",
    IntakeGuid: waitListGuid,
    GroupSize: "",
    Priority: 0,
    ManualAssignOnly: 0,
    IsFastPass: 0,
    PrefFloors: [],
    PrefBayTypes: [],
    BayGuid: null,
  };

  const [intakeData, setIntakeData] = useState(initialObject);

  let inTakeBays = intake_bays;
  const dispatch = useDispatch();

  useEffect(() => {
    setSetLoader(true);
    dataService
      .getIntakeDetails({
        VenueGuid: VENUE_GUID,
        IntakeGuid: waitListGuid,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data.response.length > 0) {
            const mappedFields = {
              Name: res.data.response[0].Name || "",
              VenueGuid: res.data.response[0].VenueGuid || VENUE_GUID,
              Phone: res.data.response[0].Phone || "",
              GroupSize: res.data.response[0].GroupSize || "",
              Priority: res.data.response[0].Priority || 0,
              ManualAssignOnly: res.data.response[0].ManualAssignOnly || 0,
              IsFastPass: res.data.response[0].IsFastPass || 0,
              PrefFloors: res.data.response[0].PrefFloors || [],
              PrefBayTypes: res.data.response[0].PrefBayTypes || [],
              BayGuid: res.data.response[0].BayGuid || null,
              IntakeGuid: waitListGuid,
            };

            // Update the state with the mapped fields
            setIntakeData(mappedFields);
          } else {
            setIntakeData({});
          }
        } else {
          toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err.Message);
      })
      .finally(() => {
        setSetLoader(false);
        setBtnIsDisable(false);
      });
  }, []);

  const onIntakeTypeChecked = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(
        IntakeBayFilters({ value: value, type: "remove", action: "type" })
      );
    } else {
      dispatch(IntakeBayFilters({ value: value, type: "add", action: "type" }));
    }
  };

  const floorCollection = Object.values(floors);

  const bayTypeCollection = Object.values(bay_types);

  const onValueChange = (phoneNumber) => {
    formik.setFieldValue("Phone", phoneNumber);
  };

  const formik = useFormik({
    initialValues: intakeData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      Name: Yup.string().trim().required("Please enter name."),
      Phone: Yup.string().trim().required("Please enter phone."),
      GroupSize: Yup.number()
        .positive()
        .max(8, "The group size cannot exceed 8")
        .typeError("group size must be a number.")
        .required("Please enter group size."),
    }),
    onSubmit: (values, actions) => {
      setBtnIsDisable(true);
      dataService
        .updateInTake(values)
        .then((res) => {
          if (res.data.status == "success") {
            toast.success("Guest updated.");
            dispatch(resetIntakeBayFilter());
            dispatch(reloadTable());
            handleClose3();
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(err.Message);
        })
        .finally(() => {
          setBtnIsDisable(false);
        });
    },
  });

  const changePriority = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("Priority", e.target.value);
    } else {
      formik.setFieldValue("Priority", 0);
    }
  };
  const changeManualAssign = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("ManualAssignOnly", e.target.value);
    } else {
      formik.setFieldValue("ManualAssignOnly", 0);
    }
  };

  return (
    <>
      {showLoader ? (
        <ThreeDotsLoader />
      ) : (
        <>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>
                {" "}
                Name <RequiredComponents />
              </Form.Label>
              <Form.Control
                type="text"
                name="Name"
                placeholder="Name"
                onChange={formik.handleChange}
                value={formik.values.Name}
              />
              {formik.touched.Name && formik.errors.Name ? (
                <div className="custom-invalid-feedback">
                  {formik.errors.Name}
                </div>
              ) : null}
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>
                    {" "}
                    Phone <RequiredComponents />
                  </Form.Label>
                  <PhoneInput
                    inputProps={{
                      name: "Phone", // should match with the schema and initialValues
                    }}
                    disableDropdown={true}
                    onChange={onValueChange} //
                    specialLabel={false}
                    value={formik.values.Phone}
                    placeholder="000 000-0000"
                    country="us"
                    disableCountryCode={true}
                    onlyCountries={["us"]}
                    id="phone-input"
                  />
                  {formik.touched.Phone && formik.errors.Phone ? (
                    <div className="custom-invalid-feedback">
                      {formik.errors.Phone}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>
                    Enter Group Size <RequiredComponents />
                  </Form.Label>
                  <Form.Control
                    placeholder="Group Size"
                    name="GroupSize"
                    onChange={formik.handleChange}
                    value={formik.values.GroupSize}
                  />
                  {formik.touched.GroupSize && formik.errors.GroupSize ? (
                    <div className="custom-invalid-feedback">
                      {formik.errors.GroupSize}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-5 ">
              <Form.Check
                label="Prioritize"
                name="Priority"
                id="Prioritize"
                type="checkbox"
                className="mb-2"
                defaultValue={1}
                checked={formik.values.Priority ? true : false}
                onChange={changePriority}
              />
              <Form.Check
                label="Manual Assign"
                name="ManualAssignOnly"
                id="ManualAssignOnlys"
                type="checkbox"
                className="mb-2"
                defaultValue={1}
                checked={formik.values.ManualAssignOnly ? true : false}
                onChange={changeManualAssign}
              />
            </div>
            <Accordion defaultActiveKey="0" className="my-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Preference</Accordion.Header>
                <Accordion.Body>
                  <Row className="intake-accordion">
                    <Col>
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="PrefFloors"
                          validateOnChange={false}
                          render={(arrayHelpers) => (
                            <ul className="list-inline gird-list justify-content-center">
                              {floorCollection.length > 0
                                ? floorCollection.map((tag, i) => (
                                    <li
                                      className="list-inline-item icon mb-3"
                                      key={`floor-${i}`}
                                    >
                                      <Form.Check
                                        label={`Floor ${tag.Floor}`}
                                        id={tag.Floor}
                                        name="PrefFloors"
                                        type="checkbox"
                                        defaultValue={tag.Floor}
                                        checked={
                                          formik.values.PrefFloors.length > 0
                                            ? formik.values.PrefFloors.some(
                                                (el) => {
                                                  return el == tag.Floor;
                                                }
                                              )
                                            : false
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            dispatch(
                                              IntakeBayFilters({
                                                value: tag.Floor,
                                                type: "add",
                                                action: "Floor",
                                              })
                                            );
                                            arrayHelpers.push(
                                              parseInt(tag.Floor)
                                            );
                                          } else {
                                            dispatch(
                                              IntakeBayFilters({
                                                value: tag.Floor,
                                                type: "remove",
                                                action: "Floor",
                                              })
                                            );
                                            const idx =
                                              formik.values.PrefFloors.indexOf(
                                                parseInt(tag.Floor)
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }}
                                      />
                                    </li>
                                  ))
                                : null}
                            </ul>
                          )}
                        />
                      </FormikProvider>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="PrefBayTypes"
                          validateOnChange={false}
                          render={(arrayHelpers) => (
                            <ul className="list-inline gird-list  mb-3 justify-content-center">
                              {bayTypeCollection.length > 0
                                ? bayTypeCollection.map((tag, i) => (
                                    <li
                                      className="list-inline-item icon mb-3"
                                      key={`bay_type-${i}`}
                                    >
                                      <Form.Check
                                        label={tag.BayTypeName}
                                        id={tag.BayTypeName}
                                        name="PrefBayTypes"
                                        type="checkbox"
                                        defaultValue={tag}
                                        checked={
                                          formik.values.PrefBayTypes.length > 0
                                            ? formik.values.PrefBayTypes.some(
                                                (el) => {
                                                  return el == tag.BayTypeId;
                                                }
                                              )
                                            : false
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            arrayHelpers.push(
                                              parseInt(tag.BayTypeId)
                                            );
                                            dispatch(
                                              IntakeBayFilters({
                                                value: tag.BayTypeId,
                                                type: "add",
                                                action: "type",
                                              })
                                            );
                                          } else {
                                            const idx =
                                              formik.values.PrefBayTypes.indexOf(
                                                parseInt(tag.BayTypeId)
                                              );
                                            arrayHelpers.remove(idx);
                                            dispatch(
                                              IntakeBayFilters({
                                                value: tag.BayTypeId,
                                                type: "remove",
                                                action: "type",
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </li>
                                  ))
                                : null}
                            </ul>
                          )}
                        />
                      </FormikProvider>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="modal-footer mt-4">
              <Button
                type="button"
                className="btn btn-secondary   fw_medium   ms-2 rounded-1   "
                variant=" "
                onClick={handleClose3}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="  btn_primary  fw_medium   ms-2 rounded-1"
                disabled={btnIsDisable}
              >
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default EditIntake;
